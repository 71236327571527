.App {
  text-align: center;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 30s linear;
  }
}


.link-button {
  background: none;
  border: none;
  color: #0080FF;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  font-family: inherit;
  padding: 0;
}

.separator {
  margin-left: 4px;
  margin-right: 4px;
}

.centered-container {
  display: flex;
  justify-content: right;
  align-items: right;
  font-size: calc(1.3vmin);
  height: 100%; /* Adjust this value if you want a specific height for the container */
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  display: none;
  margin-top: 16px;
  text-align: center;
}

.loading-spinner svg {
  width: 30px;
  height: 30px;
}

.loading-spinner circle {
  stroke-dasharray: 113;
  stroke-dashoffset: 113;
  transform-origin: center;
  animation: spin 1s linear infinite, draw 1s linear infinite;
}

.chat-loading-spinner {
  display: none;
  margin-top: 16px;
  text-align: center;
}

.chat-loading-spinner svg {
  width: 30px;
  height: 30px;
}

.chat-loading-spinner circle {
  stroke-dasharray: 113;
  stroke-dashoffset: 113;
  transform-origin: center;
  animation: spin 1s linear infinite, draw 1s linear infinite;
}

.spin-fresh {
  animation: spin 1s linear;
}

@keyframes spin-fresh {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes draw {
  0% { stroke-dashoffset: 113; }
  100% { stroke-dashoffset: 0; }
}
.ace_dark .ace_scrollbar {
  background-color: #444;
}