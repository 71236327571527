.signup-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
    padding: 2rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .signup-form-container h1 {
    margin-bottom: 1.5rem;
  }
  
  .signup-form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .signup-form label {
    font-weight: 500;
    margin: 0.5rem 0;
  }
  
  .signup-form input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem;
    font-size: 1rem;
  }
  
  .signup-form button {
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    padding: 0.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .signup-form button:hover {
    background-color: #0056b3;
  }
  
  .signup-form-container p {
    margin-top: 1rem;
  }
  
  .signup-form-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .signup-form-container a:hover {
    text-decoration: underline;
  }
  